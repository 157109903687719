<template>
  <el-dialog
      title="编辑预警"
      width="35%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 20%" ref="form">
      <el-row>
        <el-col :span="15">
          <el-form-item label="预警颜色" prop="warningColor">
            <el-select v-model="form.warningColor" placeholder="预警颜色">
              <el-option label="黄色" def value="黄色"/>
              <el-option label="红色" value="红色"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="15">
          <el-form-item label="预警类型" prop="warningType">
            <el-select v-model="form.warningType" placeholder="预警类型">
              <el-option label="售后执行" def value="售后执行"/>
              <el-option label="取件执行" value="取件执行"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="15">
          <el-form-item label="预警天数" prop="warningDay">
            <el-input type="number" v-model.number="form.warningDay"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="15">
          <el-form-item label="预警比率" prop="warningValue">
            <el-input type="number" v-model.number="form.warningValue"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateWarningCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "warning-edit",
  data() {
    return {
      form: {
        //公司名
        id:this.warning.id,
        warningColor: this.warning.warningColor,
        warningType: this.warning.warningType,
        warningDay: Number(this.warning.warningDay),
        warningValue: Number(this.warning.warningValue),
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      rules: {
        warningColor: [
          {required: true, message: '请选择预警颜色', trigger: 'change'},
        ],
        warningType: [
          {required: true, message: '请选择预警类型', trigger: 'change'},
        ],
        warningDay: [
          {required: true, message: '请输入预警天数', trigger: 'blur'},
          {type:'number',min: 1,  message: "最少一天"}
        ],
        warningValue: [
          {required: true, message: '请输入预警比率', trigger: 'blur'},
          {type:'number', min: 1, max: 100, message: "0-100"}
        ],
      },
      parentIdArray: [],
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    warning: {
      type: Object,
      required: true,
      default(){return{}},
    }
  },
  created() {

  },
  methods: {
    //检查订单校验
    updateWarningCheck: function () {
      console.log(this.form);
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.updateWarning();
        }
      })
    },
    //添加店铺
    updateWarning: function () {
      let value = "是否修改该预警值?"
      this.$confirm(value, '预警值:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/warning/updateWarning",
          params: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("修改成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>