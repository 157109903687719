<template>
  <div>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-button type="primary" @click="dialog.warningAddState = true" style="margin-bottom: 10px">添加预警参数</el-button>
      <el-table
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :data="warningList"
          border
          max-height="700"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="warningColor" label="预警颜色" align="center"/>
        <el-table-column prop="warningType" label="预警类型" align="center"/>
        <el-table-column prop="warningDay" label="预警天数" align="center"/>
        <el-table-column prop="warningValue" label="警戒比率" align="center"/>
        <el-table-column prop="createDate" label="创建时间" align="center"/>
        <el-table-column width="350" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="warning" size="mini" @click="openEdit(scope.row)">编辑</el-button>
              <el-button type="danger" size="mini" @click="delWarning(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <warningAdd v-if="dialog.warningAddState"  :state="dialog.warningAddState" @close="close"/>
    <warningEdit v-if="dialog.warningEditState" :warning="dialog.warning" :state="dialog.warningEditState" @close="close"/>
  </div>
</template>

<script>
import warningAdd from "@/pages/parameter/warning/warning-add";
import warningEdit from "@/pages/parameter/warning/warning-edit";

export default {
  name: "warning-list",
  data() {
    return {
      tenantCrop: localStorage.getItem("tenantCrop"),

        warningList: [],

      dialog: {
        warningAddState: false,
        warningEditState: false,
        warning: {},
      }

    }
  },
  components: {
    warningAdd,warningEdit,
  },
  created() {
    this.queryWarningList();
  },
  methods: {
    search() {
      this.queryWarningList();
    },
    //查询客户列表
    queryWarningList: function () {
      this.$axios({
        method: "GET",
        url: "/warning/queryList",
        params: {
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        this.warningList = response.data.data;
      })
    },
    delWarning:function (value){
      let valueTitle = "是否删除?"
      this.$confirm(valueTitle, '删除:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: '/warning/deleteById',
          params: {
            "id": value.id,
          }
        }).then(response => {
          let isValidState=response.data.code === 200;
          this.$message({
            message: isValidState ? "删除成功" : response.data.msg,
            type: isValidState ? "success" : 'error'
          });
          this.queryWarningList();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });

    },
    //打开编辑店铺
    openEdit(data) {
      this.dialog.warningEditState = true
      this.dialog.warning = data
    },
    close: function (state) {
      this.dialog.warningAddState = state;
      this.dialog.warningEditState = state;
      this.queryWarningList();
    }
  }
}
</script>

<style scoped>

</style>